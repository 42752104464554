import { useSession } from 'next-auth/react';
import { useSetRecoilState } from 'recoil';
import useSWRImmutable from 'swr/immutable';

import { NEXT_AUTH_STATUSES } from '@/lib/constants';
import { SavedItemModel, SavedItemType } from '@/lib/models/saved-item/types';
import { getSavedItemLink } from '@/lib/models/saved-item/utils';
import { lockedDialogContentState, lockedDialogIsOpenState } from '@/state/user';
import { SavedItemsListApiResponse } from '@/types/api-types';
import { request } from '@/utils/api/request';
import { hasReachedSavedItemsLimit } from '@/utils/auth';
import { getSavedItemsUrl } from '@/utils/helpers/api-urls';
import { trackUser } from '@/utils/tracking';

const ERROR_MESSAGE = 'Error fetching saved items';

const DEFAULT_OPTIMISTIC_ARGS = {
  rollbackOnError: true,
  populateCache: true,
  revalidate: true,
};

interface UseSavedItemsProps {
  isEnabled?: boolean;
  types?: SavedItemType[];
}

export const useSavedItems = ({ isEnabled = true, types }: UseSavedItemsProps = {}) => {
  const { status, data: session } = useSession();
  const setLockedDialogIsOpen = useSetRecoilState(lockedDialogIsOpenState);
  const setLockedDialogContent = useSetRecoilState(lockedDialogContentState);
  const shouldSearch = (status === NEXT_AUTH_STATUSES.AUTHENTICATED || session) && isEnabled;

  const { data, error, mutate, isLoading } = useSWRImmutable<SavedItemsListApiResponse>(
    shouldSearch ? getSavedItemsUrl(undefined, types) : null,
    request,
  );

  const getSavedItemByPath = ({ path, type }: { path: string; type: SavedItemType }): SavedItemModel | undefined => {
    return (data?.savedItems || [])
      .filter((savedItem) => savedItem.type === type)
      .find((savedItem) => {
        return getSavedItemLink(savedItem) === path;
      });
  };

  const getSavedItemByValue = ({
    key,
    value,
    type,
  }: {
    key: string;
    value: string | string[] | undefined;
    type: SavedItemType;
  }): SavedItemModel | undefined => {
    return data?.savedItems
      ?.filter((savedItem) => savedItem.type === type)
      .find((savedItem) => {
        try {
          const parsedData = JSON.parse(savedItem.data);
          return parsedData[key] === value;
        } catch {
          return false;
        }
      });
  };

  const handleAddSavedItem = async (savedItem: SavedItemModel) => {
    const result = await request(getSavedItemsUrl(), { body: savedItem }, 'POST');
    if (result) {
      trackUser.event('Created saved item');
    }
    return { savedItems: [...(data?.savedItems || []), savedItem] };
  };

  const handleDeleteSavedItem = async (savedItem: SavedItemModel) => {
    const result = await request(getSavedItemsUrl(savedItem.id), undefined, 'DELETE');
    if (result) {
      trackUser.event('Deleted saved item');
    }
    return { savedItems: data?.savedItems?.filter((item) => item.id !== savedItem.id) || [] };
  };

  const createSavedItem = async (savedItem: SavedItemModel) => {
    const hasReachedLimit = hasReachedSavedItemsLimit(data?.savedItems?.length || 0, session);
    if (!hasReachedLimit) {
      mutate(handleAddSavedItem(savedItem), {
        optimisticData: { savedItems: [...(data?.savedItems || []), savedItem] },
        ...DEFAULT_OPTIMISTIC_ARGS,
      });
    } else {
      // Show alert to user
      setLockedDialogContent({
        title: 'You have reached your favourites limit.',
        description: `Your account can have ${session?.entitlements.savedItemsLimit} saved favourites at a time. You'll need to upgrade to save more.`,
      });
      setLockedDialogIsOpen(true);
    }
  };

  const deleteSavedItem = async (savedItem: SavedItemModel) => {
    mutate(handleDeleteSavedItem(savedItem), {
      optimisticData: { savedItems: data?.savedItems?.filter((item) => item.id !== savedItem.id) || [] },
      ...DEFAULT_OPTIMISTIC_ARGS,
    });
  };

  const errorMessage = error ? ERROR_MESSAGE : undefined;

  return {
    isLoading,
    savedItems: data?.savedItems || [],
    error: errorMessage,
    createSavedItem,
    deleteSavedItem,
    getSavedItemByPath,
    getSavedItemByValue,
    isEnabled,
  };
};
